import React, { useState, useRef, useCallback, useEffect } from 'react';
import { IonContent, IonPage, IonItem,  IonInput, IonButton, IonLoading, useIonAlert, useIonViewDidEnter, IonIcon,IonGrid, IonCol, IonRow } from '@ionic/react';
import { Formik } from 'formik';
import { LoginSchema } from '../data/validations';
import './Login.css';
import { ValidateUser} from '../data/api';
import Cookies from 'universal-cookie/es6';
import { Link, RouteComponentProps } from 'react-router-dom';
import { eyeOffOutline, eyeOutline } from 'ionicons/icons';
import { toLower } from 'ionicons/dist/types/components/icon/utils';


interface OwnProps { email: any, setUser: any }
interface LoginProps extends OwnProps { }

const Login: React.FC<LoginProps> = ({ email, setUser }) => {

  const cookies = new Cookies();

  const myRef1 = useRef<HTMLIonInputElement>(null);
  const myRef2 = useRef<HTMLIonInputElement>(null);
  const [userEmail, setUserEmail] = useState(email);

  const [present] = useIonAlert();
  const [showWaiting, setShowWaiting] = useState(false);

  const [showPassword, setShowPassword] = useState(false);


  const [showLastJobId, setShowLastJobId] = useState<null|string>('');


  useEffect(() => {
    setShowLastJobId(sessionStorage.getItem("savedJobId"));
  }, []);


  useIonViewDidEnter(() => {
    if (userEmail === undefined) {
       setUserEmail(cookies.get('useremail'));
    }

    //if (myRef1.current != null) {
    //  if (myRef1.current.value === null) {
    //    myRef1.current && myRef1.current.setFocus();
    //  }
    //  else {
    //    myRef2.current && myRef2.current.setFocus();
    //  }
    //}

    myRef1.current && myRef1.current.setFocus();

  },[]);


  return (
    <IonPage>
      <IonLoading
        cssClass='my-custom-class'
        isOpen={showWaiting}
        onDidDismiss={() => setShowWaiting(false)}
        message={'Please wait...'}
        duration={3000}
      />
      <IonContent >
        

        <div className="Height120" color="transparent"></div>
        <Formik
          initialValues={{
            email: userEmail,
            password: ""
          }}

          validationSchema={LoginSchema}
          onSubmit={async values => {

            setShowWaiting(true);
            await new Promise(resolve => setTimeout(resolve, 200));


            const data = await ValidateUser(values.email, values.password);
            // Check Expiration

            if (data !== '') {

              if (data === "expired") {
                present('Sorry that your password has expired. Please change our password!', [{
                  text: 'Ok',
                   handler: () => {
                     window.location.href = '/tabs/resetpwd/' + values.email;  
                   }

                }]);

              }
              else {
                setUser(data);
                cookies.set("useremail", values.email);
                
                if (showLastJobId === null) {
                  window.location.href = '/tabs/usermenu';
                }
                else {
                  window.location.href = '/tabs/userprofile'; 
                }

              }
             
            }
            else { 
              present('Sorry, either your email or your password is incorrect. Try again!' + data, [{ text: 'Ok' }])

            }
          }}
        >

          {(formikProps: any) => (
            <form
              onSubmit={formikProps.handleSubmit}
              style={{ paddingBottom: "40px" }}
            >


              <IonGrid>
                <IonRow justify-content-center color="white">
                  <IonCol size="12" className="login-title">
                    <div text-center>
                      Login
                    </div>
                  </IonCol>
                  <IonCol size="12" className="login-colpadding">
                    <IonItem color="transparent" className="login-input">
                      <IonInput        
                        type="text"
                        name="email"
                        ref={myRef1}
                        placeholder="User email"
                        onIonChange={formikProps.handleChange}
                        value={formikProps.values.email}
                        required={true}
                        style={{textTransform:'lowercase'}}
                      />
                    </IonItem>
                  </IonCol>

                  <IonCol size="12" className="login-colpadding">
                    <IonItem color="transparent" className="login-input">
                      <IonInput
                        type={showPassword ? "text" : "password"}
                        name="password"
                        ref={myRef2}
                        placeholder="User password"
                        onIonChange={formikProps.handleChange}
                        value={formikProps.values.password}
                        required={true}
                      />
                      <div onClick={() =>
                        setShowPassword(!showPassword)
                      }>
                        <IonIcon icon={!showPassword ? eyeOffOutline : eyeOutline} slot="end" />
                      </div>
                    </IonItem>
                  </IonCol>

                  
                  <IonCol size="4" className="login-colpadding" style={{textAlign:'left',paddingTop:'10px'}}>
                    <a href="https://orem.org/privacy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                  </IonCol>

                  <IonCol size="8" className="login-colpadding" style={{textAlign:'right',paddingTop:'10px'}}>
                    <Link to="/tabs/resetpwd">Forgot Password</Link> &nbsp; | &nbsp; <Link to="/tabs/register" >New User</Link>
                  </IonCol>
                 


                  <div className="login-button-colpadding">
                    <br></br>
                  </div>

                  <IonCol size="12" className="login-button-colpadding">
                    <IonItem color="transparent" lines="none" >
                      <IonButton
                        size="large"
                        expand="block"
                        className="login-button"
                        type="submit"
                      >Submit</IonButton>
                    </IonItem>
                  </IonCol>
                </IonRow>

              </IonGrid>





             

            </form>
          )}
        </Formik>     
      </IonContent>


 

    </IonPage>
  );
}

export default Login;




import React from 'react';
import { useLocation } from 'react-router-dom'; // or your routing library
import { AutoLogin } from '../data/api';
import { useQuery } from 'react-query';
import useAuth from '../data/useAuth';
import Cookies from 'universal-cookie/es6';
import { IonLoading } from '@ionic/react';
import { IonPage } from '@ionic/react';



const AutoLoginPage = () => {
	const { setUser } = useAuth();
	const cookies = new Cookies();
	const location = useLocation();
	

	const params = new URLSearchParams(location.search);
	const token = params.get('token');
	const jobId = parseInt(params.get('jobId') || '0', 10);
	
	useQuery("JobQuery", () => AutoLogin(token, jobId), {
		onSuccess: (data) => {
			setUser(data.user.userId);
			cookies.set("useremail", data.user.email);	
			window.location.href = data.redirectUrl;
		},
		onError: () => {
			console.log("AutoLogin error");
			window.location.href = "/tabs/joblist";
		}
	});

	return (
		<IonPage>
			<IonLoading isOpen={true} message="Please wait while we log you in..." />
		</IonPage>
	);

};

export default AutoLoginPage;
import { IonButton, IonButtons, IonCard, IonCardContent, IonChip, IonCol, IonContent, IonFab, IonFabButton, IonFabList, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonMenuButton, IonModal, IonPage, IonRow, IonSearchbar, IonSegment, IonText, IonTitle, IonToolbar } from '@ionic/react';
import { calendarNumberOutline, cashOutline, fileTrayFullOutline, funnelOutline, globeOutline, helpCircleOutline, logoFacebook, logoInstagram, logoTwitter, shareSocial, swapVerticalOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import ModalFilter from '../components/ModalFilter';
import ModalSort from '../components/ModalSort';
import { GetAppJobList } from '../data/api';
import { shortDate } from '../data/utilities';
import { useGlobalContext } from '../components/MyGlobalContext';
import './Generic.css';


const JobList: React.FC = (props) => {


	sessionStorage.removeItem('savedJobId');

	const { showSearchText, setShowSearchText } = useGlobalContext();
	const { showDept } = useGlobalContext();
	const { showType} = useGlobalContext();
	const { showSort} = useGlobalContext();


	const requestQuery = useQuery("JobQuery", () => GetAppJobList(showSearchText, showDept, showType, showSort));

	const [showModalFilter, setShowModalFilter] = useState(false);
	const [showModalSort, setShowModalSort] = useState(false);

	function CheckKeyEvent(props: any) {
		if (props === 'Enter') {
			requestQuery.refetch();
		}
	}

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar color="primary" >
					<IonButtons slot="start">
						<IonMenuButton />
					</IonButtons>
					<IonTitle color="white"><h2>Current Job Openings</h2></IonTitle>
					{/*<IonCard>*/}
					{/*  <IonCardContent>*/}
					{/*    Welcome to our new and improved online job board and application portal.*/}
					{/*    If you experience any difficulties, please email hr@orem.org.*/}
					{/*  </IonCardContent>*/}
					{/*</IonCard>*/}
				</IonToolbar>
			</IonHeader>
			<IonContent color="light">
				<IonSegment  style={{backgroundImage: 'url("/assets/img/WasatchFront1024x.png")', height:'190px', backgroundSize:'cover' }} >
					<IonCardContent color="transparent" style={{paddingTop:'100px'}}>
						<IonGrid className="ion-no-padding" color="transparent">
							<IonRow>
								<IonCol size="7"><IonSearchbar showClearButton="always" value={showSearchText}
									onIonChange={e => { setShowSearchText(e.detail.value!); }}
									onIonClear={e => {
										setShowSearchText('');
										sessionStorage.setItem("showSearchText", "");
										requestQuery.refetch();
									}}
									onKeyUp={e => CheckKeyEvent(e.key!)}
								/></IonCol>
								<IonCol size="5" style={{ paddingTop: '5px' }}  >

									<IonButton color="white" style={{ height: '42px' }} onClick={() => { setShowModalFilter(true); }}>
										<IonIcon icon={funnelOutline} size="large" color="primary" />
									</IonButton>
									<IonButton color="white" style={{ height: '42px' }} onClick={() => { setShowModalSort(true); }}>
										<IonIcon icon={swapVerticalOutline} size="large" color="primary" />
									</IonButton>
									</IonCol>
								
							</IonRow>
						</IonGrid>
					</IonCardContent>
				</IonSegment >


				{/*<IonCard   >*/}
				{/*    <IonCardContent>*/}
				{/*      <IonGrid className="ion-no-padding">*/}
				{/*        <IonRow>*/}
				{/*          <IonCol ><IonSearchbar showClearButton="always" value={showSearchText}*/}
				{/*            onIonChange={e => { setShowSearchText(e.detail.value!);  }}*/}
				{/*            onIonClear={e => {*/}
				{/*              setShowSearchText('');*/}
				{/*              sessionStorage.setItem("showSearchText","");*/}
				{/*              requestQuery.refetch();*/}
				{/*            }}*/}
				{/*            onKeyUp={e => CheckKeyEvent(e.key!)}*/}
				{/*          /></IonCol>*/}
				{/*          <IonCol size="1" style={{ paddingTop: '10px' }} onClick={() => setShowModalFilter(true)} ><IonIcon icon={funnelOutline} size="large" color="primary" /></IonCol>*/}
				{/*          <IonCol size="1" style={{ paddingTop: '10px' }} onClick={() => { setShowModalSort(true); } } ><IonIcon icon={swapVerticalOutline} size="large" color="primary" /></IonCol>*/}
				{/*        </IonRow>*/}
				{/*        </IonGrid>*/}
				{/*      </IonCardContent>*/}
				{/*</IonCard>*/}
				{/*<div style={{ padding: "3px" }}></div>*/}

			 

				{requestQuery.isLoading && (
					<IonCard key="110" color="transparent">
						<IonCardContent>
							<IonItem>
								Loading...
							</IonItem>
						</IonCardContent>
					</IonCard>
				)}

				<IonList>
					{
						requestQuery.isSuccess && requestQuery.data && requestQuery.data.length > 0 && requestQuery.data.map((dataString: any, index: number) =>
					(  
							<>
								<IonItem key={dataString.hjpindex} color={index % 2 === 0 ? 'light' : 'white'}
									href="javascript:void(0);"
									onClick={() => { window.location.href = '/tabs/jobdetail/' + dataString.hjpindex }}
								>

								<IonLabel                  
									class="ion-text-wrap"
									mode="ios"
									style={{
									 
										borderLeft: '2px solid',
										borderLeftWidth: '1px',
													
									marginBottom: "5px",
									paddingTop: '5px',
									borderLeftColor: 'dark',
									paddingLeft: '20px'
									}}>                  
									{dataString.hjnew === "Y" ?
										(
											<div style={{ paddingBottom: '10px' }}>
												<div className="topRightTriangle"></div>
												<span className="adtext top-right">new</span>
												<h1>
														{dataString.hjptitle}{dataString.hjpintrnl === "Y" && (<IonText color="danger"><i> - Employee Only</i></IonText>)}
												</h1>
											</div>



										):
										(
											<div style={{ paddingBottom: '0px' }}>
												<h1>
														{dataString.hjptitle}{dataString.hjpintrnl === "Y" && (<IonText color="danger"><i> - Employee Only</i></IonText>)}
												</h1>
											</div>


											)
									 }

									{/*{dataString.hjnew === "Y" ?*/}
									{/*  (<div className="box">*/}
									{/*    <h1>{dataString.hjptitle}</h1>*/}
									{/*    <div className="ribbon"><span>new</span></div>*/}
									{/*    <h1 style={{ paddingBottom: '5px' }}> </h1>*/}
									{/*  </div>) : (<>*/}
									{/*    <h1>{dataString.hjptitle}</h1>*/}
									{/*    <h1 style={{ paddingBottom: '5px' }}></h1>*/}
									{/*  </>)*/}
									{/*}*/}
									
									<div className="hr"> </div>
									<IonIcon icon={cashOutline} style={{ fontSize: '25px' }} color="medium" />
									&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {dataString.hjpsalrang} <p></p>
									<IonIcon icon={fileTrayFullOutline} style={{ fontSize: '25px' }} color="medium" /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									{dataString.hjpjobtype === "1" && (<>Full-Time</>)}
									{dataString.hjpjobtype === "2" && (<>Part-Time</>)}
									{dataString.hjpjobtype === "3" && (<>Variable</>)}
									{dataString.hjpjobtype === "4" && (<>Seasonal</>)}<p></p>
									<IonIcon icon={calendarNumberOutline} style={{ fontSize: '25px' }} color="medium" />
									&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  {dataString.hjpend === null ? (<>Open Until Filled</>) : (<>{shortDate(dataString.hjpend)} </>)}
									{dataString.hjdaysleft != null && (<IonChip color="tertiary" outline>{dataString.hjdaysleft} days remaining</IonChip>)}
								</IonLabel>
							</IonItem>      
						</>
					)
					)
					}
				</IonList>

				<IonModal isOpen={showModalFilter} backdropDismiss={false} onDidDismiss={() => {
					sessionStorage.setItem("showDept", JSON.stringify(showDept));
					sessionStorage.setItem("showType", JSON.stringify(showType));
					setShowModalFilter(false);
					requestQuery.refetch();
				}}>
					<ModalFilter setShowModalFilter={setShowModalFilter} ></ModalFilter>
				</IonModal>
				<IonModal isOpen={showModalSort} backdropDismiss={false} onDidDismiss={() => {
					// Set local variable
					sessionStorage.setItem("showSort", JSON.stringify(showSort));
					setShowModalSort(false); requestQuery.refetch();
				}}>
					<ModalSort setShowModalSort={setShowModalSort} ></ModalSort>
				</IonModal>

		

				<IonFab horizontal="end" vertical="top" slot="fixed"  edge>
					<IonFabButton color="vimeo">
						<IonIcon icon={shareSocial}></IonIcon>
					</IonFabButton>
					<IonFabList>
						<IonFabButton color="primary" onClick={() => { window.open("https://orem.org"); }} >
							<IonIcon icon={globeOutline} ></IonIcon>
						</IonFabButton>
						<IonFabButton color="primary" onClick={() => { window.open("https://www.facebook.com/oremcity"); }} >
							<IonIcon icon={logoFacebook}></IonIcon>
						</IonFabButton>
						<IonFabButton color="primary" onClick={() => { window.open("https://twitter.com/Orem_City"); }} >
							<IonIcon icon={logoTwitter}></IonIcon>
						</IonFabButton>
						<IonFabButton color="primary" href="https://www.instagram.com/orem_city/">
							<IonIcon icon={logoInstagram}></IonIcon>
						</IonFabButton>
						<IonFabButton color="primary" onClick={() => { window.open("https://orem.org/help/"); }}>
							<IonIcon icon={helpCircleOutline}></IonIcon>
						</IonFabButton>
					</IonFabList>
				</IonFab>


			</IonContent>
		</IonPage>
	)
}



export default JobList;
